/********** Fixed sidebar menu **********/

.navik-fixed-sidebar {
  @include transition-duration-prefix("0s");

  + .burger-menu {
    @extend %fixed-sidebar-element-hide;
  }

  .logo {
    display: block;
  }
}

.navik-side-content {
  width: calc(100% - #{$fixed-sidebar-content-offset});
  transform: translateX(0%) !important;
  @include transition-duration-prefix("0s");

  > .logo {
    @extend %fixed-sidebar-element-hide;
  }
}

.fixedsidebar-bg-overlay {
  @extend %fixed-sidebar-element-hide;
}

%fixed-sidebar-element-hide {
  display: none;
}

.navik-sidebar-left {
  @include transform-prefix("translateX", "(0%)");

  ~ .fixedsidebar-bg-overlay {
    + .navik-side-content {
      margin-left: auto;
    }
  }
}

.navik-sidebar-right {
  @include transform-prefix("translateX", "(0%)");

  ~ .fixedsidebar-bg-overlay {
    + .navik-side-content {
      margin-right: auto;
    }
  }
}

.navik-menu-fixed {
  padding-top: 0;
}
